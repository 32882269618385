import React, { Component } from 'react';
import Chart from './chart';
import Column from './column';
import './style.scss';
import { connect } from 'react-redux';
import getFiltersValues from '../../../../selectors/getFiltersValues';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { isEqual } from 'lodash';
import {
  foundQuickFilters,
  prepareDataForState,
} from '../../../../helpers/paymentsPayoutsRequest';
import { getPaymentSummaryData } from '../../../../api/payments';
import { createAction } from '../../../../helpers/redux';
import ACTIONS from '../../../../constants/actionTypes';
import Loader from '../../../../components/ui/loader';
import Info from './info';
import { summaryCurrencies } from './currencies';
import CustomSelect from 'components/ui/customSelect';
import tableNames from '../../../../constants/tableNames';
import {
  QuickFiltersType,
  QuickPayoutsFiltersType,
  QuickReportsFiltersType,
} from '../../../../types/QuickFilters';
import { StoreProps } from '../../../../store';
import { State as PaymentSummaryState } from '../../../../reducers/paymentSummary';
import { fetchPaymentSummaryDataFail } from '../../../../actions/paymentSummary';

interface ConnectedProps {
  user: any;
  quickFilters:
    | QuickFiltersType
    | QuickPayoutsFiltersType
    | QuickReportsFiltersType;
  searchFilters: any;
  filters: any;
  paymentSummaryData: PaymentSummaryState;
}

type Props = IntlProps & ConnectedProps & StoreProps;

interface State {
  currency: string;
}

class SummaryView extends Component<Props, State> {
  private fetchTimerId;
  private prevRequest;
  private needUpdate = false;
  private wasInitialFetch = false;

  constructor(props) {
    super(props);

    this.state = {
      currency: 'USD',
    };
  }

  async componentDidMount() {
    if (
      Object.keys(this.props.paymentSummaryData?.data).length === 0 ||
      !this.wasInitialFetch
    ) {
      await this.fetchSummaryData();
    }
  }

  async componentDidUpdate() {
    await this.fetchSummaryData();
  }

  componentWillUnmount() {
    this.needUpdate = true;
  }

  render() {
    return (
      <div className='page-payments__summary-panel'>
        <div className={'wrapper'}>{this.renderInside()}</div>
      </div>
    );
  }

  renderInside() {
    const {
      getTranslate,
      paymentSummaryData: {
        data: { success, decline, canceled, refunded, currency, conversion },
        isFetch,
        onlyInfo,
      },
    } = this.props;

    if (isFetch) {
      return <Loader isInternal={true} />;
    }

    return (
      <>
        <Info />
        {!onlyInfo && (
          <>
            <div className={'item'}>
              <Chart conversion={conversion} />
            </div>
            <Column
              spanClass={'success'}
              text={getTranslate('payments.summary_table.success')}
              transactionsNumber={success.transactionsNumber}
              amount={success.amount}
              currency={currency}
            />
            <Column
              spanClass={'decline'}
              text={getTranslate('payments.summary_table.decline')}
              transactionsNumber={decline.transactionsNumber}
              amount={decline.amount}
              currency={currency}
            />
            <Column
              spanClass={'canceled'}
              text={getTranslate('payments.summary_table.canceled')}
              transactionsNumber={canceled.transactionsNumber}
              amount={canceled.amount}
              currency={currency}
            />
            <Column
              spanClass={'refunded'}
              text={getTranslate('payments.summary_table.refunded')}
              transactionsNumber={refunded.transactionsNumber}
              amount={refunded.amount}
              currency={currency}
            />
            <div className={'currency'}>
              <label id={'currency'}>
                {getTranslate('payments.summary_table.currency')}
              </label>
              <CustomSelect
                id={'currency'}
                label={''}
                options={summaryCurrencies}
                value={summaryCurrencies.find(
                  (item) => item.value === this.state.currency
                )}
                isSearchable={false}
                modern={false}
                onChange={({ value }) => {
                  this.onChangeCurrency(value);
                }}
                menuStyle={() => ({
                  position: 'absolute',
                  top: '-105px',
                  zIndex: 99999,
                })}
              />
            </div>
          </>
        )}
      </>
    );
  }

  fetchSummaryData() {
    const delay = 1000;
    const { filters, searchFilters } = this.props;
    clearTimeout(this.fetchTimerId);

    return new Promise<void>((resolve) => {
      if (
        this.wasInitialFetch &&
        foundQuickFilters({ filters, searchFilters })
      ) {
        this.fetchTimerId = setTimeout(() => {
          this.fetchItemsStart(resolve);
        }, delay);
      } else {
        this.fetchItemsStart(resolve);
      }
    });
  }

  onChangeCurrency(currency: string) {
    this.setState({ currency });
  }

  private fetchItemsStart = (onResolve: () => void) => {
    const { currency } = this.state;
    const {
      user: { timezone },
      filters,
      searchFilters,
      quickFilters,
      dispatch,
    } = this.props;
    const request = prepareDataForState(
      { quickFilters, filters, searchFilters },
      {
        filter: {},
      },
      { tableName: 'payments' }
    );
    request.timezone = timezone;
    request.currency = currency;
    delete request.saveFilter;

    if (isEqual(this.prevRequest, request) && !this.needUpdate) {
      return;
    }

    this.prevRequest = JSON.parse(JSON.stringify(request));

    dispatch(
      createAction({
        type: ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_START,
        payload: {},
      })
    );

    getPaymentSummaryData(request)
      .then(({ rpcId }) => {
        dispatch(
          createAction({
            type: ACTIONS.FETCH_PAYMENT_SUMMARY_DATA_START_CALCULATION,
            payload: {
              rpcId,
            },
          })
        );
      })
      .catch((payload) => {
        dispatch(fetchPaymentSummaryDataFail(payload));
      })
      .finally(() => {
        onResolve();
      });
    this.wasInitialFetch = true;
  };
}

const mapStateToProps = (state) => {
  const tableName = tableNames.payments;
  return {
    user: state.user,
    quickFilters: state.quickFilters[tableName],
    searchFilters: state.searchFilters,
    filters: getFiltersValues(state, tableName),
    paymentSummaryData: state.paymentSummaryData,
  };
};

const SummaryView_ = connect(mapStateToProps)(SummaryView);

export default addTranslation(SummaryView_);
